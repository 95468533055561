<template>
  <div>
    <coupon-aside
      v-if="aside === 'coupon'"
      v-model="aside"
      :resource="coupon"
      @changed="loadCoupons(); aside = false"
      @aside="handleAside"
    ></coupon-aside>

    <coupon-code-generator-aside
      v-if="aside === 'generator'"
      :resource="coupon"
      @changed="loadCoupons(); aside = false"
      @aside="handleAside"
    >
    </coupon-code-generator-aside>

    <v-card>
      <!-- search -->
      <v-card-text class="d-flex align-center flex-wrap pb-5">
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchQuery"
            single-line
            dense
            outlined
            hide-details
            placeholder="Search Coupon Code"
            class="invoice-list-search me-3"
          ></v-text-field>
        </div>

        <v-spacer></v-spacer>

        <v-btn
          color="success"
          class="mr-2"
          @click="aside = 'coupon';"
        >
          Create +
        </v-btn>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedTableData"
        :headers="tableColumns"
        :items="listTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.name`]="{item}">
          <span>{{ t(item.name) }}</span>
        </template>

        <template #[`item.active`]="{item}">
          <v-chip
            v-if="item.status !== 'unpublished'"
            small
            :color="item.active ? 'success' : ''"
            :class="`${item.active ? 'success' : ''}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <template #[`item.code_count`]="{item}">
          {{ item.code_count }}
          <router-link
            v-if="item.code_count > 1"
            :to="{
              name: 'coupon-code-list',
              query: {
                coupon_id: item.id
              }
            }"
            class="link"
          >
            View
          </router-link>
        </template>

        <template #[`item.value`]="{item}">
          <span>- {{ item.value }} {{ item.value_type === 'percentage' ? '%' : getLongSymbol(item.currency) }}</span>
        </template>

        <!-- trending header -->
        <template #[`item.channel_id`]="{item}">
          <span v-if="item.channel">{{ item.channel.code }}</span>
        </template>

        <!-- trending header -->
        <template #[`item.usage_limit`]="{item}">
          <span>{{ item.usage_limit ? item.usage_limit : 'unlimited' }}</span>
          <span
            v-if="item.usage_per_user"
            class="ml-2"
          >({{ item.usage_per_user }})</span>
        </template>

        <template #[`item.fit_product`]="{item}">
          <span v-if="!item.fit_product">Not Specified</span>
          <div
            v-for="product in item.products"
            :key="product.uuid"
          >
            <v-chip
              small
            >
              {{ product.product_number }}
            </v-chip>
          </div>
        </template>

        <template #[`item.fit_sku`]="{item}">
          <span v-if="!item.fit_sku">Not Specified</span>
          <div
            v-for="sku in item.skus"
            :key="sku.uuid"
          >
            <v-chip
              small
            >
              {{ sku.sku_number }}
            </v-chip>
          </div>
        </template>

        <!-- date -->
        <template #[`item.started_at`]="{item}">
          <span class="text-no-wrap">{{ item.started_at | dateTime }}</span>
        </template>

        <!-- date -->
        <template #[`item.expires_at`]="{item}">
          <span class="text-no-wrap">{{ item.expires_at | dateTime }}</span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                @click="setItem(item, 'coupon')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="setItem(item, 'generator')"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPlus }}
                  </v-icon>
                  <span>Add extra Codes</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                @click="confirmTrashCoupon(item).then(loadCoupons)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { useCurrency } from '@/composables'
import { t } from '@/plugins/i18n'
import { dateTime, price } from '@core/utils/filter'
import {
mdiDotsVertical, mdiPencil, mdiPlus, mdiTrashCan,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import CouponAside from '../coupon-resource/CouponAside.vue'
import CouponCodeGeneratorAside from '../coupon-resource/CouponCodeGeneratorAside.vue'

// composition function
import useCouponList from './useCouponList'

export default {
  components: { CouponAside, CouponCodeGeneratorAside },

  filters: { price, dateTime },

  setup() {
    const {
      listTable, searchQuery, tableColumns, options, statusFilter, totalListTable, loading, selectedTableData, loadCoupons, confirmTrashCoupon,
    } = useCouponList()

    const aside = ref(false)
    const coupon = ref(null)

    const setItem = (item, action = 'coupon') => {
      coupon.value = item
      aside.value = action
    }

    const handleAside = bol => {
      if (!bol) {
        aside.value = false
        coupon.value = false
        loadCoupons()
      }
    }

    return {
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalListTable,
      listTable,
      loading,
      selectedTableData,

      t,

      aside,
      coupon,
      setItem,
      handleAside,
      loadCoupons,
      confirmTrashCoupon,

      getLongSymbol: useCurrency().getLongSymbol,

      icons: {
        mdiDotsVertical,
        mdiPencil,
        mdiTrashCan,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.invoice-list-search {
  max-width: 10.625rem;
}
.invoice-list-status {
  max-width: 11.3rem;
}
</style>
