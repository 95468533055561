<template>
  <div>
    <v-row>
      <v-col :cols="cols">
        <v-autocomplete
          v-model="form.sync_ids"
          placeholder="Select at least one or none"
          :items="skuOptions"
          item-text="title"
          item-value="value"
          :label="label"
          clearable
          outlined
          chips
          multiple
          hide-details
          @change="handleSync"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-alert
      v-for="msg in errors"
      :key="msg"
      color="warning"
    >
      {{ msg }}
    </v-alert>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n';
import axios from '@axios';
import { } from '@mdi/js';
import { computed, ref } from '@vue/composition-api';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    skus: {
      type: Array,
      required: true,
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/skus/1/sync-categories
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    const skuOptions = computed(() => props.skus.map(sku => ({ title: `${t(sku.name)} (${sku.sku_number})`, value: sku.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        emit('change')

        return Promise.resolve(res)
      })
    }

    return {
      form,

      skuOptions,
      handleSync,
    }
  },
}
</script>
