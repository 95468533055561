import { destroyCoupon, fetchCoupons } from '@/api/order'
import { useNotifyErrors, useTableOptions } from '@/composables'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default function useCouponList() {
  const listTable = ref([])

  // Table Handlers
  const tableColumns = [
    {
      text: '#ID',
      align: 'start',
      value: 'id',
    },
    { text: 'TYPE', value: 'type' },
    { text: 'NAME', value: 'name', sortable: false },
    { text: 'ACTIVE', value: 'active' },
    { text: 'CODE', value: 'code' },
    { text: 'CODES COUNT', value: 'code_count' },
    { text: 'VALUE', value: 'value' },
    { text: 'CHANNEL', value: 'channel_id' },
    { text: 'LIMIT', value: 'usage_limit' },
    { text: 'USED', value: 'usage_count' },
    { text: 'PRODUCTS', value: 'fit_product' },
    { text: 'SKUS', value: 'fit_sku' },
    { text: 'FROM', value: 'started_at' },
    { text: 'TO', value: 'expires_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const selectedTableData = ref([])

  const loadCoupons = () =>
    fetchCoupons(
      useTableOptions(options.value, {
        search: searchQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data
        listTable.value = records
        totalListTable.value = pagination.total
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  loadCoupons()

  const trashCoupon = id => destroyCoupon(id).catch(useNotifyErrors)

  const confirmTrashCoupon = coupon =>
    new Promise((resolve, reject) => {
      store.dispatch('app/addNotifications', [
        {
          title: 'Confirm Action',
          content: `Confirm Trash coupon: <b>${coupon.code}</b> ? This code will not be able to reuse!`,
          resolve: () => trashCoupon(coupon.id).then(resolve),
          resolveText: 'Confirm',
          reject: () => reject(),
          rejectText: 'No',
        },
      ])
    })

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      loadCoupons()
      cache()
    }, 300),
  )

  return {
    tableColumns,
    searchQuery,
    options,
    listTable,
    totalListTable,
    loading,
    selectedTableData,
    loadCoupons,
    confirmTrashCoupon,
  }
}
