<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 680 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <div>
      <h1>Generate Codes for Coupon</h1>

      <p>For coupon, generate extra codes which can be applied in checkout coupon_code.</p>

      <v-textarea
        v-model="codesString"
        label="voucher codes"
        hint="enter codes seperated by comma, space or enter line break (abc123, gogo321)"
      >
      </v-textarea>
      total {{ codesArray.length }} codes. Max 999 codes.
    </div>
    <v-switch
      v-model="isShuffle"
      label="Shuffle the codes"
      hide-details
    ></v-switch>
    <div class="d-flex justify-center mt-5">
      <v-btn
        color="primary"
        :loading="loading"
        @click="handleSubmit"
      >
        Generate
      </v-btn>
    </div>

    <v-alert
      v-for="err in errors"
      :key="err"
      type="warning"
    >
      {{ err }}
    </v-alert>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeCouponCodes } from '@api/order'
import { mdiClose, mdiCloseCircle } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { shuffle } from 'lodash'

export default {
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const errors = ref([])

    const codesString = ref('')
    const isShuffle = ref(false)
    const form = ref({
      codes: [],
    })

    const codesArray = computed(() => codesString.value
      .replace(/[ ]/g, ',')
      .split(/[\n,]/)
      .filter(val => val))

    const handleSubmit = async () => {
      const data = {
        codes: isShuffle.value ? shuffle(codesArray.value) : codesArray.value,
      }

      loading.value = true
      const request = storeCouponCodes(props.resource.id, data)
      await request
        .then(res => {
          emit('created', res.data.data)
          emit('changed', res.data.data)
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,

      form,
      codesString,
      codesArray,
      isShuffle,

      icons: {
        mdiClose,
        mdiCloseCircle,
      },
    }
  },
}
</script>
