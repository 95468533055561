<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 680 : '100%'"
    class="pl-2 pr-2 pt-2 pb-2"
    app
    @input="(val) => $emit('aside', val)"
  >
    <v-container v-if="!type">
      <h3 class="mt-5 mb-5">
        Choose a coupon type
      </h3>

      <v-chip
        v-for="typeOption in typeOptions"
        :key="typeOption.value"
        class="mr-5"
        large
        @click="type = typeOption.value"
      >
        {{ typeOption.title }}
      </v-chip>

      <p class="mt-5">
        Notes:
      </p>
      <ul class="text-sm">
        <li>
          Deduction value is applied on
          <ul>
            <li>1) <b>each</b> specified product / sku, or</li>
            <li>2) the <b>entire</b> checkout if not specified.</li>
          </ul>
        </li>
        <li>
          The min / max item total rule is validated based on:
          <ul>
            <li>If specified sku / product, the coupon checks against <b>matching items total</b>.</li>
            <li>If NOT, the coupon checks against the <b>whole checkout</b> (sum of all items).</li>
          </ul>
        </li>
      </ul>

      <p class="mt-5">
        Standard Coupon Examples:
      </p>
      <ul class="text-sm">
        <li>
          Nothing specified
          <ul>
            <li>
              <b>[全單九折]</b> No specified product + no sku + deduction = 10%, whole checkout will -10% in total.
            </li>
            <li>
              <b>[滿$1999全單八八折]</b> No specified product + no sku + deduction = 12%, min_item_total=$1999, whole checkout will -12% in total.
            </li>
            <li>
              <b>[下單即減$20, 多買多減]</b> No specified product + no sku + deduction = $20, max_deduction = $40. Each item quantity at max -$20 until $40 max deduction is reached.
            </li>
            <li><b>[所有產品7折, 最多減$40]</b> Deduction = 30%, max_deduction=40, whole checkout -30% or -$40</li>
          </ul>
        </li>
        <li>
          But if specified product/sku: (e.g. 月餅)
          <ul>
            <li><b>[月餅-$50]</b> Deduction = $50, max_deduction=0, only each matched order items will -$50</li>
            <li><b>[款款月餅-$50，全單上限-$60]</b> Deduction = $50, max_deduction=60, first matched item -$50, the second -$10, others no deduction despite matched</li>
            <li><b>[所有月餅7折]</b> Deduction = 30%, max_deduction=0, each matched order items -$30. Other non-月餅 items (e.g. 燈籠) have no effects.</li>
          </ul>
        </li>
      </ul>

      <p class="mt-5">
        Free Trial Examples:
      </p>
      <ul class="text-sm">
        <li>Only effective to nature=plan. Checkout success will set membership status=free_trial</li>
        <li><b>[所有訂閱免費試用]</b> max_deduction=0, nothing specified, all plan items will -100%</li>
        <li><b>[只限月費計劃免費試用]</b> max_deduction=0, sku=mix-00001-00003, matched sku item will -100% price</li>
        <li>min_item_total=600. If $200 per person, it means user must add >= 3 ppl.</li>
        <li>max_item_total=200. If $200 per person, it means user can only add 1 ppl.</li>
        <li>Recommend only specify "Sku", no product</li>
      </ul>
    </v-container>

    <coupon-form
      v-if="type"
      :type="type"
      :resource="resource"
      :loading="loading"
      :errors="errors"
      :cols="12"
      @submit="handleSubmit"
    >
      <div
        slot="products"
      >
        <p v-show="!showProducts">
          No products specified. <a @click="showProducts = true">Specify Product</a>
        </p>

        <div v-show="showProducts">
          <v-text-field
            v-model="searchProductText"
            placeholder="Search Product name, number"
          >
          </v-text-field>

          <b
            v-if="!searchProductText"
            class="text-danger"
          >
            Options will be shown only when search text is present
          </b>

          <sync-coupon-product-form
            :products="products"
            :cols="12"
            label="Apply to only these products"
            :selected-ids="resource ? resource.products.map(p => p.id) : []"
            :sync-base-path="`/admin/coupons/${resourceData.id}/products/sync/include`"
            :sync-able="syncAble"
          />
        </div>
      </div>

      <div
        slot="skus"
      >
        <p v-show="!showSkus">
          No skus specified. <a @click="showSkus = true">Specify Sku</a>
        </p>

        <div v-show="showSkus">
          <v-text-field
            v-model="searchSkuText"
            placeholder="Search Sku name, number"
          >
          </v-text-field>

          <b
            v-if="!searchSkuText"
          >
            Options will be shown only when search text is present
          </b>

          <sync-coupon-sku-form
            :skus="skus"
            :cols="12"
            label="Apply to only these skus"
            :selected-ids="resource ? resource.skus.map(p => p.id) : []"
            :sync-base-path="`/admin/coupons/${resourceData.id}/skus/sync/include`"
            :sync-able="syncAble"
          />
        </div>
      </div>
    </coupon-form>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { storeCoupon, updateCoupon } from '@api/order'
import { fetchProducts, fetchSkus } from '@api/product'
import { mdiClose } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import CouponForm from './CouponForm.vue'
import SyncCouponProductForm from './SyncCouponProductForm.vue'
import SyncCouponSkuForm from './SyncCouponSkuForm.vue'

export default {
  components: { CouponForm, SyncCouponProductForm, SyncCouponSkuForm },

  model: {
    prop: 'active',
    event: 'aside',
  },

  props: {
    active: {
      type: Boolean,
      required: true,
    },
    resource: {
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const type = ref(props.resource?.type || null)
    const loading = ref(false)
    const resourceData = ref(props.resource || false)
    const syncAble = ref(!!props.resource)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true
      const data = { ...form }

      const request = props.resource ? updateCoupon(props.resource.id, data) : storeCoupon(data)
      const coupon = await request
        .then(res => {
          syncAble.value = true
          resourceData.value = res.data.data

          return res.data.data
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
          loading.value = false
        })

      if (!coupon) return

      // let sync-ing done after coupon success.
      setTimeout(() => {
        emit(props.resource ? 'updated' : 'created', coupon)
        emit('changed', coupon)
        loading.value = false
      }, 1000)
    }

    const showProducts = ref(props.resource?.fit_product || false)
    const searchProductText = ref('')
    const products = ref(props.resource?.products ?? [])

    watch([searchProductText], async () => {
      await fetchProducts({ search: searchProductText.value }).then(res => {
        products.value = res.data.data.records
      })
    })

    const showSkus = ref(props.resource?.fit_sku || false)
    const searchSkuText = ref('')
    const skus = ref(props.resource?.skus ?? [])

    watch([searchSkuText], async () => {
      await fetchSkus({ search: searchSkuText.value }).then(res => {
        skus.value = res.data.data.records
      })
    })

    const typeOptions = [
      { title: 'Standard', value: 'standard' },
      { title: 'Free Trial (plan)', value: 'free_trial' },
      { title: 'Free Shipping', value: 'free_shipping' },
    ]

    return {
      type,
      handleSubmit,
      loading,
      syncAble,
      errors,

      resourceData,
      showProducts,
      searchProductText,
      products,
      showSkus,
      searchSkuText,
      skus,

      typeOptions,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
